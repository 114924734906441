import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./store/index";
import { AuthProvider } from "src/contexts/AuthContext";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <Toaster toastOptions={{ duration: 3000 }} position="bottom-left" />
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
