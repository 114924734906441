import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  addMessageTemplateValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { DefaultCustomField, MessageTemplate } from "src/constants/types";
import * as Yup from "yup";
import { Box, Stack } from "@mui/material";
import {
  useCreateMessageTemplateApi,
  useUpdateMessageTemplateApi,
} from "src/api/messageTemplates";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { selectProfile } from "src/store/profile/profileSlice";
import TemplateText from "../../../../Campaigns/TemplateText/TemplateText";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflow: "visible",
  },
  "& .MuiDialogContent-root": {
    overflow: "visible"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateCompanyModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialMessageTemplate: MessageTemplate;
  messageTemplate: MessageTemplate;
  metadata: DefaultCustomField[] | undefined;
  refreshMessageTemplates: () => void;
};

export default function CreateMessageTemplateModal({
  show,
  setShowModal,
  initialMessageTemplate,
  messageTemplate,
  refreshMessageTemplates,
  metadata,
}: CreateCompanyModalType) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const selectedCompanyId = useSelector(selectedCompany);
  const createMessageTemplate = useCreateMessageTemplateApi();
  const updateMessageTemplate = useUpdateMessageTemplateApi();
  const { profile } = useSelector(selectProfile);
  const [formValues, setFormValues] =
    useState<MessageTemplate>(messageTemplate);
  useEffect(() => {
    setFormValues(formValues.id ? formValues : messageTemplate);
  }, [messageTemplate]);

  const handleClose = () => {
    setShowModal(false);
    setFormValues(initialMessageTemplate);
    setErrors({});
  };

  const handleMessageTemplateSave = () => {
    const isValid = validate(formValues);
    if (isValid && selectedCompanyId) {
      if (formValues.id) {
        updateMessageTemplate(
          formValues.name,
          formValues.text,
          formValues.id,
          selectedCompanyId
        ).then(() => {
          refreshMessageTemplates();
          handleClose();
        });
      } else {
        createMessageTemplate(
          formValues.name,
          formValues.text,
          selectedCompanyId
        )
          .then((response) => {
            setFormValues(response.data);
            handleClose();
            refreshMessageTemplates();
          })
          .catch(({ error }) => {
            if (error === "conflict") {
              setErrors({
                name: "Message template with this name already exists",
              });
            }
            if (error.message && error.message.includes("already exists")) {
              setErrors({
                name: "Message template with this name already exists",
              });
            }
          });
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addMessageTemplateValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues) => {
    try {
      addMessageTemplateValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const convertHtmlToText = (html: string) => {
    const container = document.createElement("div");
    container.innerHTML = html;
    const customFields = container.querySelectorAll("span.mention");
    customFields.forEach((customField) => {
      customField.replaceWith(
        "{{" + customField.getAttribute("data-id") + "}}"
      );
    });
    const result = container.innerText;
    return result;
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title1"
        open={show}
        fullWidth={true}
        PaperProps={{ overflowY: "visible" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {profile?.type === "user"
            ? ""
            : formValues.id
            ? "Update "
            : "Create "}
          {"Message Template"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            <TextField
              id="outlined-basic"
              label={"Name"}
              name="name"
              variant="outlined"
              disabled={profile?.role?.name === "company_user"}
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />
            <Stack direction="column">
              <Box sx={{ position: "relative", marginTop: '10px' }}>
                {formValues.id && formValues.text && <TemplateText
                  customFields={metadata!}
                  messageTemplates={undefined}
                  text={formValues.text}
                  updateText={(newText) => {
                    setFormValues({ ...formValues, text: convertHtmlToText(newText) });
                  }}
                />}
                {!formValues.id && <TemplateText
                  customFields={metadata!}
                  messageTemplates={undefined}
                  text={formValues.text}
                  updateText={(newText) => {
                    setFormValues({ ...formValues, text: convertHtmlToText(newText) });
                  }}
                />}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="end">
              {profile?.role?.name !== "company_user" && (
                <Button
                  size="small"
                  disabled={!isValidForm(formValues)}
                  variant="contained"
                  style={{ width: "150px", marginTop: "20px" }}
                  onClick={handleMessageTemplateSave}
                >
                  {formValues.id ? "Save" : "Create"}
                </Button>
              )}
              {profile?.role?.name === "company_user" && (
                <Button
                  size="small"
                  variant="contained"
                  style={{ width: "150px", marginTop: "20px" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              )}
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
