import React from "react";
import { PhoneWrapper, ChatContainer, ChatBubble, Screen } from "./styles";
import ChatFileIcon from "../ChatFileIcon/view";
import {DefaultCustomField, FileLinkWithMimeType } from "../../constants/types";
import "./styles.scss";

interface IMessage {
  isUser: boolean;
  text: string;
  links?: string[];
  linksWithMimeType?: FileLinkWithMimeType[];
  subject?: string;
  position?: number;
}

const Phone = ({
  messages,
  metadata,
  optOutText,
}: {
  messages: IMessage[];
  metadata: DefaultCustomField[] | undefined;
  optOutText: string;
}) => {

  const formatMessage = (message: string) => {
    const formattedMessage = message.replaceAll(
      /{{[^{}]+(?=})}}/g,
      (match: string) => { 
        return formatCustomFieldToHtml(match); 
      });
      return formattedMessage;
  }

  const formatCustomFieldToHtml = (text: string) => {
    const formattedText = text.replaceAll("{{", "").replace("}}", "");
    const customField = metadata?.find((c) => c.name === formattedText);
    if (customField) {
      const html = `<span data-type="mention" style="background-color:#ccc; border-radius: 7px; padding-left:3px; padding-right: 3px;" data-id="${customField.name}" data-label="${customField.description}" contenteditable="false">${customField.description}</span>`;
      return html;
    }
    return "";
  };


  return (
    <PhoneWrapper>
      <Screen>
        <ChatContainer>
          {messages.map((content, index) =>
            content.subject ||
            content.text ||
            (content.linksWithMimeType &&
              content.linksWithMimeType.length > 0) ? (
              <ChatBubble key={index} isUser={content.isUser}>
                {content.linksWithMimeType?.map(
                  (item: FileLinkWithMimeType, index) => {
                    return (
                      <ChatFileIcon
                        url={item.url}
                        mimeType={item.mimeType}
                        key={index}
                      />
                    );
                  }
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: (content.text ? formatMessage(content.text) : '') + (content.subject ?? '') }}
                  style={{ marginBottom: "6px" }}
                ></div>
                {!content.isUser && optOutText && content.position === 1 && (
                  <div style={{ marginTop: "15px" }}>{optOutText}</div>
                )}
              </ChatBubble>
            ) : null
          )}
        </ChatContainer>
      </Screen>
    </PhoneWrapper>
  );
};

export default Phone;
