import { DefaultCustomField, MessageTemplate } from 'src/constants/types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import { CustomFields } from './CustomFields'
import { PluginKey } from '@tiptap/pm/state';

export function configureSuggestions(customFields: DefaultCustomField[]) {
  return {
    pluginKey: new PluginKey("suggestions"),
    items: ({ query }: {query: string}) => {
      return customFields.map(m => {return {name: m.name, description: m.description}}).filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
    },
    char: '{',
    allowPrefixes: null,
    render: () => {
      let reactRenderer: any;
      let popup: any;
  
      return {
        onStart: (props: { clientRect?: any; editor: any }) => {
  
          if (!props.clientRect) {
            return
          }
  
          reactRenderer = new ReactRenderer(CustomFields, {
            props,
            editor: props.editor,
          })
  
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },
  
        onUpdate(props: { clientRect?: any }) {
          reactRenderer.updateProps(props)
  
          if (!props.clientRect) {
            return
          }
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },
  
        onKeyDown(props: { event: { key: string } }) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
  
            return true
          }
  
          return reactRenderer.ref?.onKeyDown(props)
        },
  
        onExit() {
          popup[0].destroy()
          reactRenderer.destroy()
        },
      }
    }
  }
}

export function configureTemplates(templates: MessageTemplate[]) {
  return {
    pluginKey: new PluginKey("templates"),
    items: ({ query }: {query: string}) => {
      return templates.map(m => {return {name: m.text, description: m.name}}).filter(item => item.description.toLowerCase().includes(query.toLowerCase()))
    },
    char: '/',
    allowPrefixes: null,
    render: () => {
      let reactRenderer: any;
      let popup: any;
  
      return {
        onStart: (props: { clientRect?: any; editor: any }) => {
  
          if (!props.clientRect) {
            return
          }
  
          reactRenderer = new ReactRenderer(CustomFields, {
            props,
            editor: props.editor,
          })
  
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },
  
        onUpdate(props: { clientRect?: any }) {
          reactRenderer.updateProps(props)
  
          if (!props.clientRect) {
            return
          }
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },
  
        onKeyDown(props: { event: { key: string } }) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
  
            return true
          }
  
          return reactRenderer.ref?.onKeyDown(props)
        },
  
        onExit() {
          popup[0].destroy()
          reactRenderer.destroy()
        },
      }
    }
  }
}