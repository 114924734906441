import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { AdminCompany } from "src/constants/types";
import {
  useCreateAdminCompanyPhoneNumberApi,
  useDeleteAdminCompanyPhoneNumberApi,
  useGetAdminCompanyPhoneNumbersApi,
  useUpdateAdminCompanyPhoneNumberApi,
} from "src/api/adminCompanies";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";

type CompanyPhonesProps = {
  company: AdminCompany;
};

const CompanyPhonesTab = (props: CompanyPhonesProps) => {
  const { company } = props;

  const [phoneNumbers, setPhoneNumbers] = useState<
    { id: string; number: string; isDeleted?: boolean; isNew?: boolean }[]
  >(company.phoneNumbers);

  const getAdminCompanyPhoneNumbers = useGetAdminCompanyPhoneNumbersApi();
  const createAdminCompanyPhoneNumbers = useCreateAdminCompanyPhoneNumberApi();
  const deleteAdminCompanyPhoneNumbers = useDeleteAdminCompanyPhoneNumberApi();
  const updateAdminCompanyPhoneNumbers = useUpdateAdminCompanyPhoneNumberApi();

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const modifiedPhoneNumbers = phoneNumbers.map((p) => {
      if (p.id === name) {
        p.number = value;
      }
      return p;
    });
    setPhoneNumbers(modifiedPhoneNumbers);
  };

  const handlePhoneNumberDelete = (id: string) => {
    const modifiedPhoneNumbers = phoneNumbers.map((p) => {
      if (p.id === id) {
        p.isDeleted = true;
      }
      return p;
    });
    setPhoneNumbers(modifiedPhoneNumbers);
  };

  const addNewPhoneNumber = () => {
    const newPhoneNumbers = phoneNumbers.concat([
      {
        id: Date.now().toString(36) + Math.random().toString(36).substring(2),
        number: "",
        isDeleted: false,
        isNew: true,
      },
    ]);
    setPhoneNumbers(newPhoneNumbers);
  };

  const handleAdminCompanyPhonesSave = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: any = [];
    phoneNumbers.forEach((p) => {
      if (p.isDeleted) {
        if (!p.isNew) {
          promises.push(deleteAdminCompanyPhoneNumbers(company.id, p.id));
        }
      } else if (p.isNew && !p.isDeleted) {
        promises.push(
          createAdminCompanyPhoneNumbers(
            company.id,
            p.number?.startsWith("+1") ? p.number : "+1" + p.number
          )
        );
      } else if (p.id && !p.isNew && !p.isDeleted) {
        promises.push(
          updateAdminCompanyPhoneNumbers(
            company.id,
            p.id,
            p.number?.startsWith("+1") ? p.number : "+1" + p.number
          )
        );
      }
    });
    Promise.all(promises).then(() => {
      toast.success('phones are updated');
    });
  };

  useEffect(() => {
    getAdminCompanyPhoneNumbers(company.id).then((response) => {
      const phoneNumbers = response.data && response.data.length > 0 ? response.data : [
        {
          id:
            Date.now().toString(36) + Math.random().toString(36).substring(2),
          number: "",
          isDeleted: false,
          isNew: true,
        },
      ]
      setPhoneNumbers(phoneNumbers);
    });
  }, []);

  return (
    <Box width="70%">
      {phoneNumbers && (
        <Box sx={{ marginTop: "20px" }}>
          {phoneNumbers
            .filter((p) => !p.isDeleted)
            .map((p) => (
              <Box key={p.id} sx={{ width: "100%", marginTop: "20px" }}>
                <TextField
                  sx={{ width: "92%" }}
                  id="outlined-basic"
                  label={"Phone"}
                  name={p.id}
                  inputProps={{
                    pattern: "d*",
                    min: 10,
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={p.number?.replace("+1", "")}
                  onChange={handlePhoneNumberChange}
                />
                <IconButton
                  onClick={() => {
                    handlePhoneNumberDelete(p.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          <Stack direction="row" gap="10px">
            <Button
              size="small"
              variant="contained"
              style={{ width: "150px", marginTop: "20px" }}
              onClick={addNewPhoneNumber}
            >
              Add New Phone
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{ width: "150px", marginTop: "20px" }}
              onClick={handleAdminCompanyPhonesSave}
            >
              Save
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default CompanyPhonesTab;
