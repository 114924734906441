import React, {
  Fragment,
  useState,
  useMemo,
  CSSProperties,
  useEffect,
} from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  ContentContainer,
  UrlContainer,
  FileContainer,
  ErrorMessage,
} from "./styles";
import { acceptTypes, urlValidateSchema } from "./constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { ValidationErrors } from "src/constants/validations";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import {
  focusedStyle,
  baseStyle,
  acceptStyle,
  rejectStyle,
  Cont,
} from "./styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  uploadFileToS3,
  useCreateMediaApi,
  useVerifyMediaApi,
} from "src/api/files";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { FileLinkWithMimeType } from "../../constants/types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type AttachFileUrlModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFileChange: (link: FileLinkWithMimeType) => void;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const getProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default function AttachFileUrlModal({
  show,
  setShowModal,
  handleFileChange,
}: AttachFileUrlModalType) {
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [url, setUrl] = useState("");
  const createMedia = useCreateMediaApi();
  const verifyMedia = useVerifyMediaApi();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const validate = async (url: string) => {
    try {
      await urlValidateSchema.validate({ url }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const validateUrl = async () => {
    const isValid = await validate(url);
    if (isValid) {
      handleFileChange({
        url,
      });
      handleClose();
    }
  };

  const pdfValidator = (file: { type: string; size: number; }) => {
    const maxPdfSize = 1024 * 1024;
    const fileSize = 5 * 1024 * 1024;
    if (file.type === 'application/pdf' && file.size > maxPdfSize) {
      return {
        code: "pdf-size-too-large",
        message: `File upload failed. Only pdf files up to 1MB are allowed. Please check and try again.`,
      };
    }

    if (file.size > fileSize) {
        return {
          code: "size-too-large",
          message: `File upload failed. Only specific formats up to 5MB are allowed. Please check and try again.`,
        };
      }

    return null;
  };

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragAccept,
    isFocused,
    isDragReject,
    fileRejections,
  } = useDropzone({
    noClick: true,
    accept: acceptTypes,
    noKeyboard: true,
    disabled: loading,
    multiple: false,
    validator: pdfValidator
  });

  const style: CSSProperties = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    if (fileRejections.length > 0) {
      if (fileRejections[0].errors[0].code) {
        setErrors({
          file: fileRejections[0].errors[0].message,
        });
      }
    } else {
      setErrors({});
    }
  }, [fileRejections]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setLoading(true);
      let mediaId: number;
      const mimeType = acceptedFiles[0].name.includes(".vcf")
        ? "text/vcard"
        : acceptedFiles[0].type;
      createMedia(mimeType)
        .then(({ data }) => {
          const { id, url } = data;
          mediaId = id;
          return uploadFileToS3(acceptedFiles[0], url);
        })
        .then(() => {
          return verifyMedia(mediaId);
        })
        .then(({ data }) => {
          if (data.status === "active") {
            handleFileChange({
              url: data.url,
              mimeType: data.mimeType,
            });
            handleClose();
          } else {
            setErrors({ file: "File upload failed, please try again." });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [acceptedFiles]);

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Attach
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <ContentContainer>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Upload" {...getProps(0)} />
              <Tab label="Link To File" {...getProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tab} index={0}>
            <FileContainer>
              <div className="container">
                <Cont {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>{`Drag and drop file here, or click to select file`}</p>
                  <div style={{ textAlign: "center", padding: "0px 40px" }}>
                    {
                      "Allowed formats: *.txt, *.vcf, *.ics, *.jpg, *.jpeg, *.png, *.gif, *.3gp, *.mp4, *.pdf, *.m4a"
                    }
                  </div>
                  {loading ? (
                    <LoadingButton
                      loading
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      style={{ margin: "25px 0px 10px 0px" }}
                    >
                      Loading
                    </LoadingButton>
                  ) : (
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      onClick={open}
                      disabled={loading}
                      startIcon={<CloudUploadIcon />}
                      style={{ margin: "25px 0px 10px 0px" }}
                    >
                      Upload
                    </Button>
                  )}
                </Cont>
              </div>
              <ErrorMessage>{errors.file}</ErrorMessage>
            </FileContainer>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <UrlContainer>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                error={!!errors.url}
              >
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">File URL</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  value={url}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const withHttps = (url: string) =>
                      url && !/^(http(s?)):\/\//i.test(url)
                        ? `https://${url}`
                        : url;
                    const withHttpsUrl = withHttps(e.target.value);
                    setUrl(withHttpsUrl);
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">
                  {errors.url}
                </FormHelperText>
              </FormControl>
              <FormHelperText id="outlined-weight-helper-text">
                Free or Public link shorteners are often blocked by carriers due
                to frequent abuse by spammers. Use full URLs or custom link
                shorteners to ensure deliverability.
              </FormHelperText>
              <Button
                variant="contained"
                style={{ alignSelf: "end", marginTop: "25px", width: "130px" }}
                onClick={validateUrl}
              >
                Attach
              </Button>
            </UrlContainer>
          </CustomTabPanel>
        </ContentContainer>
      </BootstrapDialog>
    </Fragment>
  );
}
