import { Box, Button, Stack, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CreateAdminCompanyModal from "src/pages/Companies/CreateCompanyModal/view";
import { AdminCompany, DefaultCustomField, MessageTemplate } from "src/constants/types";
import CreateMessageTemplateModal from "src/pages/Settings/Components/MessageTemplates/CreateMessageTemplateModal/view";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";

interface IProps<TEntity> {
  selectedCompanyId?: string;
  tableName: "companies" | "messageTemplates";
  fetchData: (
    params: object
  ) => Promise<{ data: { items: TEntity[]; count: number } }>;
  getItem: (id: string, companyId?: string) => Promise<{ data: TEntity }>;
  deleteItem: (id: string, companyId?: string) => Promise<void>;
  pageSize: number;
  initialData: TEntity;
  metadata?: DefaultCustomField[];
  formatColumns: (
    handleEditItem: (id: string) => void,
    handleDeleteItem: (id: string) => void
  ) => GridColDef[];
}

function BhiTable<TEntity>({
  fetchData,
  getItem,
  deleteItem,
  pageSize = 5,
  initialData,
  formatColumns,
  tableName,
  selectedCompanyId,
  metadata,
}: IProps<TEntity>) {
  const { profile } = useSelector(selectProfile);
  const handleItemEdit = (id: string) => {
    if (selectedCompanyId) {
      getItem(id, selectedCompanyId).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    } else {
      getItem(id).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
  React.useState<GridColumnVisibilityModel>({
    status: false,
  });

  const handleItemDelete = (id: string) => {
    if (selectedCompanyId) {
      deleteItem(id, selectedCompanyId).then(loadData);
    } else {
      deleteItem(id).then(loadData);
    }
  };

  const columns = formatColumns(
    handleItemEdit,
    handleItemDelete
  );
  const [rows, setRows] = useState<TEntity[]>();
  const [data, setData] = useState<TEntity>(initialData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [showCreateModal, setCreateModal] = useState(false);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: pageSize,
    });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const getQueryParams = () => {
    const params: {
      limit: number;
      offset: number;
      sort: string;
      query: string | undefined;
      companyId?: string;
    } = {
      limit: pageSize,
      offset: paginationModel.page * pageSize,
      sort: `${sortModel[0].field}_${sortModel[0].sort}`,
      query: searchQuery,
    };

    if (selectedCompanyId) {
      params.companyId = selectedCompanyId;
    }

    if (!searchQuery) {
      delete params.query;
    }

    return params;
  };

  const loadData = async () => {
    setIsLoading(true);
    const response = await fetchData(getQueryParams());
    setRows(response.data.items);
    setTotalCount(response.data.count);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [paginationModel, sortModel, searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box sx={{ paddingTop: "20px;" }}>
      <Stack
        direction={"row"}
        sx={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          border: "1px solid #F0F0F0",
          padding: "15px",
        }}
        justifyContent={"space-between"}
        height="40px"
      >
        <TextField
          size="small"
          onChange={handleChange}
          InputProps={{ endAdornment: <SearchIcon /> }}
        />
        {(tableName === 'companies' || (tableName === 'messageTemplates' && profile && profile?.role?.name !== "company_user")) && <Button
          sx={{ height: "40px;" }}
          size="small"
          variant="contained"
          onClick={() => {
            setData(initialData);
            setCreateModal(true);
          }}
        >
          {tableName === "companies" && `Create Company`}
          {tableName === "messageTemplates" && `Add New Template`}
        </Button>}
        {tableName === "companies" && (
          <CreateAdminCompanyModal
            initialCompany={initialData as AdminCompany}
            adminCompany={data as AdminCompany}
            show={showCreateModal}
            setShowModal={setCreateModal}
            refreshCompanies={loadData}
          />
        )}
        {tableName === "messageTemplates" && (
          <CreateMessageTemplateModal
            metadata={metadata}
            initialMessageTemplate={initialData as MessageTemplate}
            messageTemplate={data as MessageTemplate}
            show={showCreateModal}
            setShowModal={setCreateModal}
            refreshMessageTemplates={loadData}
          />
        )}
      </Stack>

      <Box sx={{ marginTop: "-5px" }}>
        <DataGrid
          slotProps={{
            row: {
              onMouseEnter: () => {
              },
              onMouseLeave: () => {},
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          columns={columns}
          rows={rows}
          pagination
          disableMultipleRowSelection
          disableRowSelectionOnClick
          pageSizeOptions={[pageSize]}
          sortingMode="server"
          paginationMode="server"
          paginationModel={paginationModel}
          sortModel={sortModel}
          disableColumnFilter
          sortingOrder={["asc", "desc"]}
          rowSelection={false}
          rowCount={totalCount}
          loading={isLoading}
          onPaginationModelChange={(
            newPaginationModel: GridPaginationModel
          ) => {
            setPaginationModel(newPaginationModel);
          }}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel);
          }}
          sx={{
            fontSize: "12px",
            padding: 0,
            height: "calc(100vh - 250px)",
            "&, [class=MuiDataGrid]": {
              borderTop: "none",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
            },
            "& .MuiDataGrid-columnSeparator": {
              opacity: "1 !important;",
            },
            "& .MuiDataGrid-container--top [role=row]": {
              backgroundColor: "rgb(37, 69, 151)",
              color: "white",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: 1,
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              opacity: 1,
              color: "white",
            },
            "& .MuiDataGrid-columnHeader[data-field=subscription.autorenew] .MuiDataGrid-columnSeparator--sideRight, .MuiDataGrid-columnHeader[data-field=actions] .MuiDataGrid-columnSeparator--sideRight":
              {
                display: "none",
              },
            "& .MuiDataGrid-columnSeparator:hover": {
              color: "white",
            },
            "& .action": {
              display: "none",
            },
            "& .MuiDataGrid-row:hover .bhiTableActions": {
              display: "initial",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default BhiTable;
