import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "src/pages/Login/view";
import Groups from "src/pages/Groups";
import PrivateRoute from "src/routes/PrivateRoute";
import Campaigns from "src/pages/Campaigns";
import Keywords from "src/pages/Keywords";
import Profile from "src/pages/Profile";
import Settings from "src/pages/Settings";
import ForgotPassword from "src/pages/ForgotPassword";
import SetPassword from "src/pages/SetPassword";
import GroupContacts from "src/pages/GroupContacts";
import AccessDenied from "src/pages/AccessDenied";
import MultiMessageCampaign from "src/pages/Campaigns/MultiMessageCampaign/view";
import MultiJoinCampaign from "src/pages/Campaigns/MultiJoinCampaign/view";
import TextFlyerCampaign from "src/pages/Campaigns/TextFlyerCampaign/view";
import Inbox from "src/pages/Inbox";
import Companies from "src/pages/Companies";
import EditCompany from "src/pages/Companies/EditCompany/view";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      <Route path="/set-password" element={<SetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Inbox />
          </PrivateRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <PrivateRoute>
            <Groups />
          </PrivateRoute>
        }
      />
      <Route
        path="/group/:groupId"
        element={
          <PrivateRoute>
            <GroupContacts />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns"
        element={
          <PrivateRoute>
            <Campaigns />
          </PrivateRoute>
        }
      />
       <Route
        path="/campaigns/text-flyer/:campaignId?"
        element={
          <PrivateRoute>
            <TextFlyerCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns/multiple-message/:campaignId?"
        element={
          <PrivateRoute>
            <MultiMessageCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns/multiple-join/:campaignId?"
        element={
          <PrivateRoute>
            <MultiJoinCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/keywords"
        element={
          <PrivateRoute>
            <Keywords />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
       <Route
        path="/admin/companies"
        element={
          <PrivateRoute>
            <Companies />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/companies/edit/:companyId"
        element={
          <PrivateRoute>
            <EditCompany />
          </PrivateRoute>
        }
      />
       <Route
        path="/inbox"
        element={
          <PrivateRoute>
            <Inbox />
          </PrivateRoute>
        }
      />
    </Route>
  )
);

export default router;
