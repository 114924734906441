import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormGroup,
  Tooltip,
} from "@mui/material";
import FileUploaderInput from "src/components/FileUploaderInput/view";
import { Message, MessageType } from "../types";
import dayjs from "dayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import {
  DefaultCustomField,
  FileLinkWithMimeType,
  MessageTemplate,
} from "src/constants/types";

import Divider from "@mui/material/Divider";
import TemplateText from '../../TemplateText/TemplateText';

export type MessageProps = {
  metadata: DefaultCustomField[] | undefined;
  messageTemplates: MessageTemplate[] | undefined;
  message: Message;
  updateMessage: React.Dispatch<React.SetStateAction<Message>>;
};

export default function MessageForm({
  message,
  updateMessage,
  messageTemplates,
  metadata,
}: MessageProps) {
  const [messageValues, setMessageValues] = useState<Message>({
    ...message,
    isCaptureContact: message.dataCapture ? true : false,
  });
  const [delayPeriod, setDelayPeriod] = useState<string>("m");
  const [delayValue, setDelayValue] = useState<string>(
    message.scheduledType === "delay" && message.delay
      ? message.delay.slice(0, -1)
      : ""
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (messageValues.scheduledType === "delay" && messageValues.delay) {
      setDelayPeriod(messageValues.delay[messageValues.delay?.length - 1]);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      updateMessage({ ...messageValues });
    }
  }, [messageValues]);

  const handleChangeCaptureContactResponseCustomField = ({
    target,
  }: SelectChangeEvent<string>) => {
    setMessageValues({
      ...messageValues,
      dataCapture: target.value,
    });
  };

  const handleScheduleTypeChange = (
    e: SelectChangeEvent<"immediate" | "delay" | "time" | "reply">
  ) => {
    const { value } = e.target;
    if (value === "time") {
      setDelayValue("");
      setDelayPeriod("m");
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        delay: null,
      });
    }
    if (value === "delay") {
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        time: null,
      });
    }
    if (value === "immediate") {
      setDelayValue("");
      setDelayPeriod("m");
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        time: null,
        delay: null,
      });
    }
    if (value === "reply") {
      setDelayValue("");
      setDelayPeriod("m");
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        time: null,
        delay: null,
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "scheduledType") {
      if (value === "time") {
        setDelayValue("");
        setDelayPeriod("m");
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          delay: null,
        });
      }
      if (value === "delay") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
        });
      }
      if (value === "immediate") {
        setDelayValue("");
        setDelayPeriod("m");
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
          delay: null,
        });
      }
      if (value === "reply") {
        setDelayValue("");
        setDelayPeriod("m");
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
          delay: null,
        });
      }
    } else {
      setMessageValues({ ...messageValues, [name]: value });
    }
  };

  const handleMessageTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    messageType: string
  ) => {
    setMessageValues({ ...messageValues, type: messageType as MessageType });
  };

  const handleLinksUpdate = (links: FileLinkWithMimeType[]) => {
    setMessageValues({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
    updateMessage({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
  };

  const handleSchedulePeriodChange = ({
    target,
  }: SelectChangeEvent<string>) => {
    setDelayPeriod(target.value);
    setMessageValues({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
    updateMessage({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
  };

  const handleDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value && +value > 0) {
      setDelayValue(value);
      setMessageValues({
        ...messageValues,
        time: null,
        [name]: `${value}${delayPeriod}`,
      });
    }
  };

  const handleTimeSave = async (date: dayjs.Dayjs | null) => {
    setMessageValues({
      ...messageValues,
      delay: null,
      time: dayjs(date).toDate().toISOString(),
    });
  };

  return (
    <Fragment>
      <FormControl
        fullWidth
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: "0 0",
          marginBottom: "10px",
        }}
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={message.type}
          exclusive
          onChange={handleMessageTypeChange}
          aria-label="messageType"
        >
          <ToggleButton value="sms">SMS</ToggleButton>
          <ToggleButton value="mms">MMS</ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      {message.type === "mms" && (
        <FileUploaderInput
          key={message.id}
          links={message.linksWithMimeType as FileLinkWithMimeType[]}
          updateLinks={handleLinksUpdate}
        />
      )}
      {message.type === "mms" && (
        <>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Subject (optional)"
            name="subject"
            variant="outlined"
            value={message.subject}
            onChange={handleChange}
            style={{ marginTop: "20px" }}
          />
          <FormHelperText id="outlined-weight-helper-text">
            Different devices and applications display the subject of an MMS in
            different ways. Avoid including critical information in the subject.
          </FormHelperText>
        </>
      )}
      <Stack direction="column">
        <Box sx={{ position: "relative" }}>
          {metadata && (
            <>
              <TemplateText
                customFields={metadata!}
                messageTemplates={messageTemplates}
                text={messageValues.text}
                updateText={(newText) =>
                  setMessageValues({ ...messageValues, text: newText })
                }
              />
            </>
          )}
        </Box>
      </Stack>
      <FormControl />
      <Stack direction="row" alignItems="center" sx={{ marginTop: "-15px" }}>
        <Tooltip
          placement="top"
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
          title={
            "If selected, any valid contact response will update the specified contact metadata."
          }
        >
          <FormGroup>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={
                <Checkbox
                  name="isCaptureContact"
                  checked={messageValues.isCaptureContact}
                  onChange={(e) => {
                    setMessageValues({
                      ...messageValues,
                      dataCapture: e.target.checked
                        ? messageValues.dataCapture
                        : "",
                      isCaptureContact: e.target.checked,
                    });
                  }}
                />
              }
              label="Capture response to message as"
            />
          </FormGroup>
        </Tooltip>
        {metadata && (
          <FormControl sx={{ width: "30%", marginLeft: "10px" }}>
            <Select
              sx={{ height: "35px" }}
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={!messageValues.isCaptureContact}
              value={
                messageValues.isCaptureContact
                  ? messageValues.dataCapture
                  : null
              }
              onChange={handleChangeCaptureContactResponseCustomField}
              name="dataCapture"
            >
              {metadata
                .filter(
                  (m) =>
                    m.name.startsWith("contact.") &&
                    m.name !== "contact.standard.phone_number"
                )
                .sort((a, b) =>
                  a.description
                    .toLocaleLowerCase()
                    .localeCompare(b.description.toLowerCase())
                )
                .map((c) => (
                  <MenuItem value={c.name} key={c.id}>
                    {c.description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <>
        <Divider sx={{ marginTop: "20px" }} />
        <Stack
          direction="row"
          alignItems="center"
          height="30px"
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <FormControl
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: "13px",
            }}
          >
            <FormLabel style={{ color: "#000" }}>Schedule:</FormLabel>
            <Select
              sx={{ marginLeft: "10px", marginRight: "10px", height: "35px" }}
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-label"
              size="small"
              value={messageValues.scheduledType}
              onChange={handleScheduleTypeChange}
              name="scheduledType"
            >
              <MenuItem value={"immediate"} key={"immediate"}>
                Immediately
              </MenuItem>
              <MenuItem
                disabled={message.position === 1}
                value={"delay"}
                key={"delay"}
              >
                After Delay
              </MenuItem>
              <MenuItem
                disabled={message.position === 1}
                value={"reply"}
                key={"reply"}
              >
                After Response
              </MenuItem>
            </Select>
          </FormControl>
          {message.scheduledType === "delay" && (
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column",
                marginTop: "13px",
              }}
            >
              <Box display={"flex"}>
                <TextField
                  sx={{
                    marginRight: "10px",
                    "& .MuiOutlinedInput-input": {
                      height: "35px",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Period"
                  name="delay"
                  size="small"
                  type="number"
                  inputProps={{ min: 1 }}
                  variant="outlined"
                  value={delayValue}
                  onChange={handleDelayChange}
                />
                <Select
                  sx={{ height: "35px" }}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={delayPeriod}
                  onChange={handleSchedulePeriodChange}
                >
                  <MenuItem value={"m"} key={"minutes"}>
                    Minutes
                  </MenuItem>
                  <MenuItem value={"h"} key={"hours"}>
                    Hours
                  </MenuItem>
                  <MenuItem value={"d"} key={"days"}>
                    Days
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          )}
          {message.scheduledType === "time" && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{ marginTop: "5px" }}
                  components={["DateTimePicker"]}
                >
                  <DateTimePicker
                    slotProps={{ textField: { size: "small" } }}
                    label="Date and Time Scheduled"
                    disablePast
                    onChange={(value) => handleTimeSave(value)}
                    defaultValue={message.time ? dayjs(message.time) : null}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </>
          )}
        </Stack>
      </>
    </Fragment>
  );
}
