import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  addAdminCompanyValidationSchema,
  editAdminCompanyValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { AdminCompany, Application } from "src/constants/types";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  useCreateAdminCompanyApi,
  useGetAdminApplicationsApi,
} from "src/api/adminCompanies";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateCompanyModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialCompany: AdminCompany;
  adminCompany: AdminCompany;
  refreshCompanies: () => void;
};

export default function CreateAdminCompanyModal({
  show,
  setShowModal,
  initialCompany,
  adminCompany,
}: CreateCompanyModalType) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const navigate = useNavigate();
  const createAdminCompany = useCreateAdminCompanyApi();
  const getAdminApplications = useGetAdminApplicationsApi();

  const [formValues, setFormValues] = useState<AdminCompany>(adminCompany);
  const [applications, setApplications] = useState<Application[]>();

  useEffect(() => {
    getAdminApplications({ limit: 10000, offset: 0 }).then((response) => {
      if (response.data.items) {
        setApplications(response.data.items);
      }
    });
  }, [formValues.application.id]);

  useEffect(() => {
    setFormValues(formValues.id ? formValues : adminCompany);
  }, [adminCompany]);

  const handleClose = () => {
    setShowModal(false);
    setFormValues(initialCompany);
    setErrors({});
  };

  const handleIsMessagingProfile = (isMessagingProfile: boolean) => {
    setFormValues({ ...formValues, createMessageProfile: isMessagingProfile });
  };

  const handleChangeApplication = ({ target }: SelectChangeEvent<string>) => {
    const application = applications?.find((a) => a.id === target.value);
    if (application) {
      setFormValues({
        ...formValues,
        application: application,
      });
    }
  };

  const handleAdminCompanySave = () => {
    const timeZones = moment.tz.zonesForCountry("US");
    const currentTimezone = timeZones.includes(moment.tz.guess())
      ? moment.tz.guess()
      : timeZones[0];

    const isValid = validate(formValues);
    if (isValid) {
      createAdminCompany({
        name: formValues.name,
        applicationId: formValues.application.id,
        timezone: currentTimezone,
        createMessageProfile: !!formValues.createMessageProfile,
      })
        .then((response) => {
          navigate(`edit/${response.data.id}`);
        })
        .catch(({ error }) => {
          if (error === "conflict") {
            setErrors({ name: "Entity with this name already exists" });
          }
          if (error.message && error.message.includes("already exists")) {
            setErrors({
              name: "Entity with this name already exists",
            });
          }
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState: AdminCompany) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addAdminCompanyValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues) => {
    try {
      if (adminCompany.id) {
        editAdminCompanyValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      } else {
        addAdminCompanyValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      }
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {formValues.id ? "Update " : "Create "}
          {"Company"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            {applications && (
              <Box>
                <Stack width="100%" gap="5px" direction="row">
                  <TextField
                    sx={{ width: "49%" }}
                    id="outlined-basic"
                    label={"Name"}
                    name="name"
                    variant="outlined"
                    value={formValues.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors?.name || ""}
                  />
                  <FormControl sx={{ width: "49%", marginLeft: "1%" }}>
                    <InputLabel>Application</InputLabel>
                    <Select
                      label="Application"
                      MenuProps={{ disableScrollLock: true }}
                      value={formValues.application.id}
                      onChange={handleChangeApplication}
                      name="fieldName"
                    >
                      {applications
                        .sort((a, b) =>
                          a.name
                            .toLowerCase()
                            .localeCompare(b.name.toLowerCase())
                        )
                        .map((c) => (
                          <MenuItem value={c.id} key={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>

                {!formValues.id && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.createMessageProfile}
                          onChange={(event) =>
                            handleIsMessagingProfile(event.target.checked)
                          }
                        />
                      }
                      label="Create a Messaging Profile for this company?"
                    />
                  </FormGroup>
                )}
                {formValues.id && (
                  <Box sx={{ marginTop: "20px" }}>
                    <TextField
                      sx={{ width: "49%", marginRight: "1%" }}
                      id="outlined-basic"
                      label={"Message Profile ID"}
                      name="messagingProfileId"
                      variant="outlined"
                      value={formValues.messagingProfileId}
                      onChange={handleChange}
                      error={!!errors.messagingProfileId}
                      helperText={errors?.messagingProfileId || ""}
                    />
                    <TextField
                      sx={{ width: "49%", marginLeft: "1%" }}
                      id="outlined-basic"
                      label={"TCR Campaign ID"}
                      name="tcrCampaignId"
                      variant="outlined"
                      value={formValues.tcrCampaignId}
                      onChange={handleChange}
                      error={!!errors.tcrCampaignId}
                      helperText={errors?.tcrCampaignId || ""}
                    />
                  </Box>
                )}
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    size="small"
                    disabled={!isValidForm(formValues)}
                    variant="contained"
                    style={{ width: "150px", marginTop: "20px" }}
                    onClick={handleAdminCompanySave}
                  >
                    Create
                  </Button>
                </Stack>
              </Box>
            )}
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
